import type { UnwrapRef } from 'vue';

export const useClientSideOnlyComputed = <T>(
  defaultValue: T,
  proc: () => Ref<UnwrapRef<T>>
) => {
  if (import.meta.server) {
    return computed(() => defaultValue);
  }
  const value = ref(defaultValue);

  onMounted(() => {
    const value_ = proc();
    watch(
      value_,
      (v) => {
        value.value = v;
      },
      {
        immediate: true,
      }
    );
  });

  return computed(() => {
    return value.value;
  });
};
